import { HttpData, HttpNoData } from "../../../../Core";

export async function updateShiftAsync(id, body, jwt) {
    
    const response = await HttpData(`/api/v1/shifts/${id}`, 'PUT', jwt, body);

    return response.data;
}

export async function getExtraWorksV1(jwt) {
	const response = await HttpNoData('/api/v1/ExtraWorks', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getExtraWorkCategoriesV1(jwt){

    const response = await HttpNoData('/api/v1/extraWorkCategories', 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function getShiftsAllV1 (date,jwt) {

    const response = await HttpNoData('/api/v1/shifts/all/history?date=' + date, 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function getShiftsMyV1 (date,jwt) {

    const response = await HttpNoData('/api/v1/shifts/history?date=' + date, 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}


export const deleteExtraWorkPull = async (jwt, id) => {
	const response = await HttpNoData(`/api/v1/ExtraWorkPulls/${id}`, 'DELETE', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.statusSuccessful
}

export const createExtraWorkPull = async (jwt, body) => {

	const response = await HttpData(`/api/v1/ExtraWorkPulls`, 'POST', jwt, body)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export const updateExtraWorkPull = async (jwt, id, body) => {

	const response = await HttpData(`/api/v1/ExtraWorkPulls/${id}`, 'PUT', jwt, body)

	if (!response.statusSuccessful) console.log(response.error)

	return response.statusSuccessful
}